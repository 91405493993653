@use "../config/" as *;
// form related common style
.form {
    &-area {
        .form-banner {
            height: 25rem;
            @include background(var(--secondary-1100), "login/mobile/login-bg.png", center/cover no-repeat);
            @extend %relative;
            @extend %flex-c-fe;
            @extend %px-8;
            &::after {
                content: "";
                pointer-events: none;
                z-index: var(--z-overlay);
                inset: 0;
                background: linear-gradient(0deg, var(--secondary-1100) 5%, var(--secondary-1100) 0%, rgba(0, 0, 0, 0) 42%);
                @extend %absolute;
            }
            img {
                width: 26rem;
            }
        }
    }
    &-title {
        @extend %font-22-pb;
        @extend %mb-1;
        @extend %secondary-1000;
    }
    &-control {
        height: var(--input-height);
        border: 0.1rem solid var(--neutral-100);
        box-shadow: 0 0 .4rem 0 clr(neutral-100, 5);
        @extend %neutral-1000;
        @extend %w-100;
        @extend %px-3;
        @extend %half-radius;
    }
    &-sub-title {
        flex-basis: 100%;
        @extend %neutral-600;
        @extend %font-14;
    }
    &-head {
        @extend %mb-3;
    }
    &-body {
        @extend %mt-4;
    }
    &-group {
        @extend %mb-2;
        .title {
            @extend %mb-0;
            @extend %font-16;
        }
    }
    &-select-box {
        @extend %relative;
    }
    &-otp {
        max-width: 28rem;
        .title {
            @extend %font-14;
        }
        .text {
            @extend %font-10;
            @extend %neutral-400
        }
        .number {
            font-weight: 700;
            @extend %neutral-800;
        }
    }
    &-list {
        @extend %p-3;
    }
    &-alert {
        @extend %p-2;
        @extend %flex-n-c;
        @extend %mb-2;
        @extend %secondary-200-bg;
        @extend %half-radius;
        .icon {
            flex-shrink: 0;
            width: 4rem;
            &::before {
                @extend %secondary-700;
                @include icon(info, 22);
            }
        }
        .text {
            flex: 1;
            @extend %font-12-pm;
        }
    }
    &-mcq {
        @extend %neutral-50-bg;
        @extend %p-4-3;
        &-title {
            @extend %font-14-pb;
        }
        &-head {
            border-bottom: .1rem solid clr(secondary-900, 5);
            @extend %pb-3;
        }
        &-body {
            @extend %pt-3;
        }
    }
    &-success {
        display: grid;
        justify-items: center;
        @extend %text-center;
        @extend %secondary-1000;
        img {
            width: 12rem;
            height: 12rem;
            @extend %mb-4;
        }
        a {
            text-decoration: underline;
            @extend %font-16-pr;
        }
        h3 {
            @extend %font-40;
        }
        p {
            @extend %font-16-pb;
            @extend %mb-3;
        }
    }
}
.btn-skip {
    padding: .2rem var(--space-2);
    @extend %ml-auto;
    @extend %secondary-400-bg;
    @extend %secondary-1000;
    @extend %p-1;
    @extend %font-12-pb;
    @extend %rounded-radius;
    @extend %capitalize;
}
select {
    option {
        @extend %neutral-1000;
    }
}
// checkbox
.checkbox-container {
    @extend %flex-n-fs;
    @extend %gap-2;
    input[type=checkbox] {
        @extend %d-none;
        &:checked ~ .checkmark {
            border: 0;
            @extend %primary-500-bg;
            &::after {
                @include icon(checkmark, 11);
                @extend %secondary-1000;
            }
        }
    }
    .text {
        user-select: none;
        @extend %font-12;
    }
}
.checkmark {
    width: 1.6rem;
    height: 1.6rem;
    flex-shrink: 0;
    border-radius: .3rem;
    margin-top: .3rem;
    border: .1rem solid clr(secondary-1000);
    @extend %flex-c-c;
}
// radio buttons
.radio-container {
    @extend %flex;
    @extend %flex-wrap;
    .radio-group {
        @extend %flex;
        @extend %gap-2;
        @extend %py-2;
        .radio-text {
            @extend %font-14-pr;
        }
        input[type=radio],
        input[type=checkbox] {
            accent-color: clr(secondary-700);
            &:checked + .radio-text {
                font-weight: 700;
                @extend %secondary-700;
            }
        }
    }
}
// label style
.control-label {
    @extend %font-12;
    @extend %neutral-800;
    @extend %mb-1;
}
// mobile number field which has country dropdown in it
.mobile-number-wrapper {
    display: flex;
    flex-direction: column-reverse;
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
        -webkit-text-fill-color: clr(neutral-1000);
        -webkit-box-shadow: 0 0 0 40rem clr(neutral-50) inset;
    }
}
// button of verify or send otp
.send-otp {
    @extend %mb-4;
}
// wherever there is link 
.link-text {
    text-decoration: underline;
    // @extend %primary-50;
}
// messages for error or success
.error-msg {
    @extend %font-10;
    @extend %error-500;
}
.success-msg {
    @extend %font-10;
    @extend %success-500;
}
.global-msg {
    background-color: hsl(var(--hsl-white)/0.05);
    @include backdropBlur(.5rem);
    @extend %w-100;
    @extend %mb-3;
    @extend %pr-4;
    @extend %pl-6;
    @extend %py-2;
    &:empty {
        @extend %d-none;
    }
    .message {
        @extend %my-1;
        &::marker {
            @extend %neutral-1000;
        }
    }
}
.error .message {
    @extend %error-500;
}
// specifically targeted which are used otherwhere 
.custom-form {
    @extend %flex-wrap;
    @extend %gap-2;
    @extend %flex;
    .seperator {
        @extend %neutral-100-5;
    }
    .mobile-otp {
        @extend %mb-0;
    }
}
// input mobile field 
.mobile-number {
    @extend %relative;
    .country-flag {
        border-radius: 0;
        width: 3rem;
        height: 1.8rem;
        aspect-ratio: 2/1;
        z-index: var(--z-country);
    }
    &-wrapper {
        @extend %relative;
    }
    .form-control {
        padding-left: calc(var(--country-width) + var(--space-2));
        letter-spacing: .3rem;
        border: .1rem solid clr(neutral-100);
        @extend %p-2;
        @extend %neutral-1000;
        @extend %font-16-pm;
        &::placeholder {
            letter-spacing: .1rem;
            @extend %font-14-pr;
        }
    }
    .form-select-box {
        width: var(--country-width);
        z-index: var(--z-select-box);
        height: var(--input-height);
        position: absolute;
        bottom: 0;
        left: 0;
        border: solid var(--neutral-100);
        border-width: .1rem 0 .1rem .1rem;
        border-radius: var(--half-radius) 0 0 var(--half-radius);
        @include separator(40%, y);
        button[type="button"]:disabled {
            @extend %transparent-bg;
        }
        select {
            border: 0;
            top: 0;
            @extend %absolute;
            @extend %h-100;
            @extend %w-100;
            @extend %full-radius;
            &:focus-visible {
                border: 0;
                outline: 0;
            }
        }
        .selected-label {
            height: 100%;
            width: 4rem;
            z-index: var(--z-index1);
            pointer-events: none;
            @include position(0, null, null, null, absolute);
            @extend %pl-12;
            @extend %neutral-50-bg;
            @extend %font-12-pr;
            @extend %flex-c-c;
            @extend %full-radius;
        }
        &::after {
            left: var(--country-width);
            background-color: clr(secondary-900);
        }
    }
    // country code dropdown also can be used in other dropdowns
    .selected-title {
        height: var(--input-height);
        background-color: hsl(var(--hsl-white)/0.05);
        @extend %text-left;
        @extend %w-100;
        @extend %flex-n-c;
        @extend %px-2;
        @extend %neutral-1000;
        .country-flag {
            @extend %mr-1;
        }
        &::after {
            right: var(--space-1);
            @extend %absolute;
            @include icon(chevron-down, 12);
            @extend %transition;
        }
        &[aria-expanded=true] {
            &::after {
                transform: rotate(180deg);
            }
            & ~ .select-list {
                @include dropdown(open);
            }
        }
        > .btn-text {
            width: 4rem;
            flex-shrink: 0;
            // @extend %
            @extend %text-right;
            @extend %font-14-pm;
        }
    }
    .select-list {
        position: absolute;
        top: 100%;
        left: 0;
        list-style: none;
        max-height: 15rem;
        width: 20rem;
        z-index: var(--z-select);
        @include dropdown(close);
        @include custom-scroll();
        @extend %secondary-100-bg;
        @extend %y-auto;
        @extend %px-2;
    }
    .list-item {
        @extend %flex;
        @extend %gap-2;
        @extend %py-2;
        @extend %w-100;
        &.active {
            &::before {
                width: 2rem;
                height: 2rem;
                order: 1;
                border: .1rem solid;
                @include icon(checkmark);
                @extend %flex-c-c;
                @extend %ml-auto;
                @extend %circle-radius;
            }
            .btn-text {
                font-weight: 700;
            }
        }
        .country-flag {
            border-radius: 0;
        }
        .btn-text {
            flex: 1;
            @extend %text-left;
            @extend %font-14-pm;
        }
        &:not(:last-child) {
            position: relative;
            @include separator(100%, x, .1rem, black)
        }
    }
}
// otp section 
.otp {
    &-input {
        @extend %flex-fs-n;
    }
    &-input-wrapper {
        flex-direction: column;
    }
    &-wrapper {
        @extend %mt-3;
    }
    &-wrap {
        width: max-content;
        border: .1rem solid clr(neutral-100);
        box-shadow: 0 0 .4rem 0 clr(neutral-100, 5);
        @extend %half-radius;
        @extend %relative;
        @extend %flex;
        .form-element {
            &:first-child {
                border-radius: var(--half-radius) 0 0 var(--half-radius);
                @extend %hidden;
            }
            &:nth-child(6) {
                border-radius: 0 var(--half-radius) var(--half-radius) 0;
                @extend %hidden;
            }
        }
        .form-control {
            width: 5rem;
            height: 5rem;
            border: 0;
            border-radius: 0;
            box-shadow: none;
            @extend %text-center;
            @extend %font-18;
            @extend %hidden;
            @extend %neutral-800;
            @extend %p-0;
        }
        .check {
            width: 2rem;
            height: 2rem;
            right: -3rem;
            @extend %pos-y-center;
            @extend %circle-radius;
            @extend %neutral-1000;
            @extend %flex-c-c;
        }
        &.success {
            .check {
                border: .1rem solid clr(neutral-50);
                @extend %success-500-bg;
                &::before {
                    @extend %neutral-50;
                    @include icon(checkmark, 12);
                }
            }
        }
        &.error {
            border: .1rem solid clr(error-500);
            .check {
                @extend %error-500-bg;
                &::before {
                    @extend %neutral-50;
                    @include icon(close, 8);
                }
            }
        }
    }
}
.resend-otp {
    @extend %flex-n-c;
    @extend %py-2;
    .text {
        @extend %font-14;
        @extend %neutral-800;
    }
    .text-wrap {
        flex-direction: column;
        @extend %w-100;
        @extend %flex;
    }
    .otp {
        text-decoration: none;
        border: .1rem solid clr(secondary-1000);
        border-radius: var(--space-4);
        flex-shrink: 0;
        width: 11rem;
        height: 3rem;
        @extend %flex-c-c;
        @extend %ml-auto;
        @extend %font-12-pb;
        @extend %secondary-1000;
    }
    .timer {
        @extend %neutral-500;
        @extend %font-10;
    }
}
.disclaimer {
    @extend %mt-3;
}
.disclaimer,
.acknowledge {
    @extend %mb-3;
}
.avatar-section {
    width: 6.5rem;
    height: 6.5rem;
    aspect-ratio: 1/1;
    flex-shrink: 0;
    border: .4rem solid clr(primary-500);
    @extend %circle-radius;
    @extend %mr-2;
    @extend %relative;
    .image-upload {
        @extend %d-none;
    }
    .avatar {
        @extend %circle-radius;
    }
}
.show-avatar {
    @include position(null, var(--space-1-neg), var(--space-1), null, absolute);
    &::after {
        width: 1.8rem;
        height: 1.8rem;
        cursor: pointer;
        @include icon(camera, 10);
        @extend %secondary-1000;
        @extend %flex-c-c;
        @extend %circle-radius;
        @extend %primary-500-bg;
    }
}
.avatar {
    &-list {
        @include card-count(4, var(--space-3));
        @extend %flex-wrap;
        @extend %flex;
    }
    &-item {
        aspect-ratio: 1/1;
    }
    &-input {
        @extend %d-none;
        &:checked ~ .avatar-img {
            border: .1rem solid;
            @extend %primary-500-bg;
        }
    }
    &-img {
        aspect-ratio: 1/1;
        @include border(1, secondary, 2);
        @extend %transition;
        @extend %circle-radius;
        @extend %w-100;
        @extend %primary-500-bg-1;
    }
}
@media screen and (min-width: $tablet-min-width) {
    .form {
        &-area {
            .form-banner {
                height: 30rem;
                padding-inline: var(--space-5);
                @include background(var(--secondary-1100), "login/login-bg.png", center/cover no-repeat);
                img {
                    width: 100%;
                    height: auto;
                }
            }
        }
        &-sub-title {
            font-size: 1.6rem;
        }
        &-otp {
            .title {
                font-size: 1.4rem
            }
        }
        &-list {
            display: flex;
            align-items: center;
            @include background(var(--secondary-100), 'login/login-pattern.jpg', center/cover no-repeat);
        }
        &-banner img {
            height: 100%;
        }
        &-mcq {
            padding: var(--space-6);
        }
        &-success {
            padding-block: 4rem;
            h3 {
                margin-bottom: 3rem;
            }
        }
    }
    // otp section 
    .otp {
        &-wrap {
            .form-control {
                width: 4rem;
                height: 4rem;
                border: 0;
            }
        }
    }
    .disclaimer,
    .acknowledge {
        margin-bottom: var(--space-4);
    }
    .otp-wrapper {
        width: 100%;
    }
    .otp-input-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .resend-otp {
        margin-bottom: 0;
    }
}