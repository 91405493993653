@use "./config" as *;
@forward "./main-scss/";
@forward "./form/common";

.waf-row:has(.waf-calendar-sync){
	background: linear-gradient(0deg, var(--secondary-1000) 25.82%, var(--secondary-1000) 113.23%);
}
.main-wrap{
	@extend %hide-breadcrumb;
}

.waf-calendar-sync{
	--modal-width:100%;
	height:var(--window-inner-height);
	height: 100dvh;
	@extend %w-100;
	@extend %flex-c-c;
	&::before {
		content: "";
		bottom: 0;
		left: 0;
		pointer-events: none;
		@extend %h-100;
		@extend %w-100;
		@extend %absolute;
		@include background(null, "patterns/popup-bg.png", bottom/auto repeat-x);   
	}
	.waf-body {
		width:var(--modal-width);
		@extend %text-center;
		@extend %neutral-50-bg;
		@extend %p-7-4;
		@extend %half-radius;
	}
	.fav-club-head {
		.title {
			@extend %font-18-pb;
			@extend %secondary-1000;
			@extend %uppercase;
			@extend %mb-3;
		}
		.text{
			@extend %font-14-pr;
			@extend %mb-4;
		}
	}
	.social-list {
		border: none;
		@extend %flex-c-c;
		@extend %gap-10;
		.name{
			@extend %font-14-pm;
			@extend %capitalize;
		}
		.social-logo{
			height: 5.3rem;
			width: 5.3rem;
			.social-img{
				@extend %h-100;
				@extend %w-100;
				object-fit:contain; 
			}
		}
	}
	.email-form{
		@extend %mt-5;
		.email-element{
			align-items: self-start;
			@extend %flex-column;

		}
		.email-label{
			@extend %font-12-pr;
			@extend %neutral-800;
			@extend %mb-2;
			@extend %capitalize;
		}
		.email-input{
			border: .1rem solid clr(neutral-100);
			@extend %half-radius;
			@extend %w-100;
			@extend %p-2-3;
		}
		.email-btn{
			@extend %secondary-1000;
			@extend %primary-500-bg;
			@extend %font-14-pb;
			@extend %text-center;
			@extend %w-100;
			@extend %mt-3;
			@extend %p-2;
			@extend %uppercase;
			@extend %half-radius;

		}
	}
}

@media screen and (min-width: $tablet-min-width) {
	.waf-calendar-sync{
		--modal-width:42rem;
		padding-top: var(--header-height);
		padding: var(--space-7) var(--space-10);
		.fav-club-head {
			.title {
				font-size: 2.2rem;
			}
			.text{
				font-size: 1.6rem;
			}
		}
	}
}